import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Group, Col, Section, CtaButton } from "../components/common"

import img from "../images/home-top-bg.jpg"
import img2 from "../images/photo1.jpg"

import analyizeImg from "../images/analyze.jpg"

import circles from "../images/s-factor/circles.png"
import mspi from "../images/s-factor/mspi.jpg"
import boxes from "../images/s-factor/boxes.jpg"

class SFactorPage extends Component {
  render() {
    return (
      <>
        <Layout darkHeader={true}>
          <SEO title="S-Factor" />
          <div className="page page-s-factor">
            <div style={{ height: 90 }} />
            <Section>
              <img src={img2} style={{ width: "100%" }} />
            </Section>
            {/* <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>
                    We put the
                    <br />S in ESG
                  </h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    Typically, ESG has three pillars E, S &amp; G. Our expertise
                    is in the S vertical, which is substantially
                    underrepresented and should be the most critical aspect of
                    investment criteria for both risk and reward.
                  </p>

                  <h4>
                    Each pillar has several “themes” or topics within the
                    pillar, example:
                  </h4>

                  <p>
                    Social = Human Rights, Child Labour, Modern Slavery, Labour
                    conditions etc.
                  </p>
                  <h4>Each theme has dozens of “indicators”, example:</h4>
                  <p>
                    Labour Standards = within the measures of labour standards
                    there are dozens of topics including “forced labour”. Within
                    forced labour there are dozens of “indicators” for this one
                    topic. “Indicators of exploitation”, include qualifying
                    whether employees are working <span>(1)</span> working
                    excessive days or hours <span>(2)</span> Have bad living
                    conditions <span>(3)</span> Are in a hazardous work
                    environment <span>(4)</span> no salary or <span>(5)</span>{" "}
                    there is no respect of labour laws or <span>(6)</span> no
                    contract signed, and so – these are only a few of dozens –
                    just looking at one topic within one theme from 100s and
                    1000
                  </p>
                </Col>
              </Group>
            </Section> */}
            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Compliance and Materiality</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    There is a substantial information gap in our knowledge
                    about what qualifies a responsible investment.
                  </p>
                  <p>
                    A flood of new concepts, content, themes, frameworks and
                    indicators can be overwhelming to grasp, and even more
                    cumbersome and costly to qualify and then quantify.
                  </p>
                  <p>
                    This is the GAP that The S Factor Co’s MSPI can fulfill.
                  </p>
                  <h4>
                    Each indicator may then relate to several international
                    frameworks and standards (there are 1,000s), for example:
                  </h4>
                  <p>
                    Determining whether employees are “working excessive hours”
                    may actually relate to several frameworks from non-related
                    entities. Example, International Labour Standard (ILO),
                    International Finance Corporation, Performance Standards
                    (IFC, PS), United Nations Declaration on Human Rights
                    (UNDHR) ...and so on.
                  </p>
                  <p>
                    To make our tools useful, we have essentially standardized
                    and simplified the metrics across frameworks.
                  </p>
                  <p>
                    <h5>Our database covers</h5>
                    <ul>
                      <li>11,000+ companies,</li>
                      <li>74 social impact themes,</li>
                      <li>45 frameworks,</li>
                      <li>
                        {" "}
                        300+ social specific indicators and thousandsof metrics.
                      </li>
                    </ul>
                  </p>
                  <p>
                    It is of value to know how many frameworks you qualify for,
                    as you may need to comply to various in different
                    jurisdictions.
                  </p>
                  <p>
                    It is not enough for a company to publish a policy on each
                    theme or topic. In order to truly measure impact, to qualify
                    it and further to quantify it, they must demonstrate
                    implementation with material evidence and ongoing monitoring
                    and evaluation of performance. Qualifying statements must
                    have third-party validation.
                  </p>
                  <p>
                    In today’s ESG landscape, of the 1,000’s of possible social
                    indicators, or Social Factors (S Factors) most financial
                    products include 3 or 4, and almost every product on the
                    market relies on company supplied content.
                  </p>
                  <p>
                    The MSPI has automated this process across all themes,
                    frameworks and indicators for the Social Factors.
                  </p>
                </Col>
              </Group>
              <Group>
                <Col size="whole">
                  <div style={{ height: 32 }} />
                  <img src={img} style={{ width: "100%" }} alt="pic" />
                  <div style={{ height: 32 }} />

                  <h4 className="bigger">
                    Are companies doing more than they are simply required to
                    do, because it’s the right thing to do? Do they understand
                    how they impact people, and does it matter to them?
                  </h4>
                </Col>
              </Group>
            </Section>
            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />
                  <h3>
                    The S Factor
                    <br />
                    Score
                  </h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    The S Factor score (“the score”) qualifies evidence of
                    material information, from multiple validated sources, on
                    company’s social performance, data availability, and
                    industry relevance - benchmarking hundreds of themes and
                    indicators in accordance with dozens of frameworks. There
                    are 4 possible scores associated with each of the 300+
                    indicators.
                  </p>
                  <p>
                    The objective is to determine whether companies understand
                    what social impact is, how to measure it, how to implement
                    measures to mitigate risks associated with it, and how to
                    track and report on it – whether its required regulatory
                    compliance or best-practice.
                  </p>
                  <p>
                    The S Factor score also measures whether a company is
                    actually doing what they say, validated my materiality and
                    third-parties. As a result, The S Factor score measures the
                    true litmus of company leadership, its culture and
                    commitment to social impact. The score is updated quarterly,
                    initially by company and soon by each location of operation.
                  </p>
                  <p />
                  <img
                    src={circles}
                    style={{ width: "75%", margin: "0 auto", display: "block" }}
                    alt="pic"
                  />
                </Col>
              </Group>
            </Section>
            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />
                  <h3>The MSPI</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    The MSPI considers The S Factor score plus controversies and
                    public sentiment in its ranking of companies on social
                    impact, by country, by industry, by theme and by indicator
                    in correlation to financial performance. Companies in this
                    ranking demonstrate high S Factor score, positive public
                    sentiment and superior financial returns. This deep-dive
                    analysis separates the best from the rest. You can assure
                    that the companies doing well here, are a class-above,
                    getting it right with everything else.
                  </p>

                  <img
                    src={mspi}
                    style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
                    alt="pic"
                  />

                  <p className="disclaimer">
                    *Controversies: Include any scandals, disasters, litigation,
                    disputes, or protest against the company, its operations, or
                    investments. Controversies and sentiment are benchmarked on
                    any or all of the S Factor score themes and weighted
                    accordingly, resulting in an aggregated percentile.
                  </p>
                  <p className="disclaimer">
                    ** Sentiment: scans of 50,000 news and social media sites in
                    14 languages for both positive and negative sentiment on key
                    themes; results are then blended with the controversy score
                    for an aggregated percentile.
                  </p>
                </Col>
              </Group>
            </Section>
            <Section hasDivider={false}>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />
                  <h3>
                    The S Factor
                    <br />
                    Ratings
                  </h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    While the MSPI identifies the top performing companies, the
                    information created by the S Factor measures can also inform
                    risk ratings, as it relates to social issues and the
                    probabilities of disruption and the level of preparedness to
                    manage the uncertainties. The S Factor rating provides a
                    rounded and comprehensive measure of a company’s social
                    performance.
                  </p>

                  <img
                    src={boxes}
                    style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
                    alt="pic"
                  />
                </Col>
              </Group>
            </Section>

            <Section dark={true} hasDivider={false}>
              <Group>
                <img src={analyizeImg} style={{ width: "100%" }} alt="pic" />
                <div style={{ height: 100 }} />

                <Col size="third">
                  <div className="headingAccent" />
                  <h3>
                    The S Factor
                    <br />
                    Fund
                  </h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    The S Factor Fund (TSFF) is an emerging social impact Fund
                    of Funds (FOF) manager with an innovative strategy
                    investment strategy utilizing the proprietary methodology of
                    the MacCormick Social Performance Index (MSPI).
                  </p>

                  <h4>
                    The TSFF will target 5 critical areas in social impact:
                  </h4>
                  <p>
                    <ul>
                      <li>Education</li>
                      <li>Healthcare</li>
                      <li>Microfinance</li>
                      <li>Next Billion</li>
                      <li>Women</li>
                    </ul>
                  </p>

                  <p>
                    The <span>MSPI</span> considers The S Factor® score along
                    with controversies and public sentiment in its ranking of
                    companies on social impact, by country, by industry, by
                    theme and by indicator in correlation to financial
                    performance. Companies in this ranking demonstrate high S
                    Factor score, positive public sentiment and superior
                    financial returns. This deep-dive analysis separates the
                    best from the rest. You can assure that the companies doing
                    well here, are a class-above, getting it right with
                    everything else.
                  </p>
                  <p>
                    We invest in social impact funds who understand the
                    strategic importance of their social impacts as an asset for{" "}
                    <span>SROI</span>. Our research shows that these companies
                    deliver both superior returns and a sustainable, positive
                    impact.
                  </p>
                  <p>
                    The <span>TSFF</span> uses a data-driven, investment
                    valuation model to measure social impact performance. Our
                    approach is the first of its kind to assign value to
                    companies’ specific to their mission-based social impacts in
                    correlation with their financial return.
                  </p>
                  <p>
                    Using big data and machine learning techniques we quantify
                    unstructured data, news, sentiment, controversies and other
                    information, in addition to the company financials.
                  </p>
                  <p>
                    Unlike traditional financial factor models which ignore
                    these important attributes of long-term shareholder value
                    directly linked to impact performance.
                  </p>
                  <p>
                    Our purpose-aligned investment portfolios out-perform global
                    equities and create a positive sustainable impact as
                    measured not only by the UN Sustainable Development Goals (
                    <span>SDG</span>s) but 14 other similar frameworks. Creating
                    a solution for both purpose and profitability.
                  </p>
                  <p>
                    We manage both global long-short and global long-only equity
                    strategies, generating unique quantitative alpha for both
                    equity-hedged and market exposure, each tailored to
                    different investor portfolio allocations.
                  </p>
                  <p />
                  <h3>
                    Check back here regularly for updated information about the
                    launch of this fund in 2019.
                  </h3>

                  <div style={{ height: 140 }} />
                </Col>
              </Group>
            </Section>
          </div>
        </Layout>
      </>
    )
  }
}

export default SFactorPage
